/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
export const getTrendingComedians = (viewsArr, howLong) => {
  const numberDays =
    howLong === 'today'
      ? 1
      : howLong === 'this-week'
      ? 7
      : howLong === 'this-month'
      ? 30
      : howLong === 'this-year'
      ? 365
      : 10000000;
  const ms = Date.now() - 1000 * 60 * 60 * 24 * numberDays;
  const viewsArrFiltered = viewsArr.filter((view) => {
    const vDate = new Date(view.createdAt);
    const vInMs = vDate.getTime();
    if (vInMs > ms) {
      return view;
    }
  });
  const viewsArrSorted = [];
  viewsArrFiltered.forEach((view) => {
    viewsArrSorted[view.comedianId] = viewsArrSorted[view.comedianId]
      ? viewsArrSorted[view.comedianId] + 1
      : 1;
  });
  let newArr = [];
  for (const [key, value] of Object.entries(viewsArrSorted)) {
    newArr.push({ comedianId: key, views: value });
  }
  newArr = newArr.sort((a, b) =>
    a.views < b.views ? 1 : a.views > b.views ? -1 : 0
  );
  return newArr;
};
