import { useEffect, useState } from 'react';

export const useGetAllComediansViews = () => {
  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? process.env.GATSBY_DEV_ENDPOINT
      : process.env.GATSBY_PROD_ENDPOINT;
  const [views, setViews] = useState([]);
  useEffect(() => {
    const getComedianViews = async () => {
      const res = await fetch(`${baseUrl}/api/v1/comedians`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      })
        .then(function (response) {
          return response.json();
        })
        .catch(function (error) {
          console.log('Request failed', error);
        });
      setViews(res?.data || []);
    };
    getComedianViews();
  }, []);
  return { views };
};
