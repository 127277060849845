/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box, Button } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import { PageStyles } from '.';
import { useGetAllComediansTrendingThisWeek } from '../utils/useGetAllComediansTrendingThisWeek';
import { FourOhFour } from '../components/FourOhFour';

const updateComedianViews = (allComediansViews) => {
  const mutations = allComediansViews
    .sort((a, b) => (a.views > b.views ? -1 : a.views < b.views ? 1 : 0))
    .map((comedian, index) => ({
      patch: {
        id: comedian._id,
        set: {
          viewsThisWeek: comedian.views,
          rankThisWeek: index + 1,
        },
      },
    }));

  fetch(
    `https://${process.env.GATSBY_SANITY_PROJECT_ID}.api.sanity.io/v1/data/mutate/${process.env.GATSBY_DATASET_NAME}`,
    {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${process.env.GATSBY_AUTH_TOKEN}`,
      },
      body: JSON.stringify({ mutations }),
    }
  )
    .then((response) => response.json())
    .then((result) => console.log(result))
    .catch((error) => console.error(error));
};

export default function ViewsPage({ data }) {
  const { comedians } = data;
  const allComediansViews = useGetAllComediansTrendingThisWeek([
    ...comedians.nodes,
  ]);
  const isDevelopment = process.env.NODE_ENV === 'development';

  return (
    <>
      {isDevelopment && (
        <>
          <SEO title="Yuks" />
          <PageStyles>
            <Box p="10px">
              {allComediansViews.length <= 0 && (
                <Box>Waiting for response...</Box>
              )}
              <Box>
                {allComediansViews.length > 0 && (
                  <Button
                    onClick={() => updateComedianViews(allComediansViews)}
                  >
                    Update views this week
                  </Button>
                )}
              </Box>
            </Box>
          </PageStyles>
        </>
      )}
      {!isDevelopment && (
        <>
          <SEO title="Page Not Found" />
          <FourOhFour />
        </>
      )}
    </>
  );
}

export const query = graphql`
  query {
    comedians: allSanityComedian {
      nodes {
        _id
        id
        firstName
        lastName
        slug {
          current
        }
        _createdAt
        avatar {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
