/* eslint-disable array-callback-return */
import { getTrendingComedians } from './getTrendingComedians';
import { useGetAllComediansViews } from './useGetAllComediansViews';

export const useGetAllComediansTrendingThisWeek = (comediansArr) => {
  const { views } = useGetAllComediansViews();
  const comediansByTrending = getTrendingComedians(views, 'this-week');
  console.log({ thisWeek: comediansByTrending });
  const comediansFiltered = [...comediansArr].filter((com) => {
    const trendingIds = [...comediansByTrending].map(
      (trend) => trend.comedianId
    );
    if (trendingIds.includes(com.id)) {
      const trendingComedian = comediansByTrending.find(
        (c) => c.comedianId === com.id
      );
      const trendingComedianViews = trendingComedian
        ? trendingComedian.views
        : 0;
      com.views = trendingComedianViews;
      return com;
    }
  });
  return comediansFiltered;
};
